.login-button {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border-radius: 20px;
    background-color: #e83e8c;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #99268d;
  }
  
  @media (max-width: 768px) {
    .login-button {
      padding: 0.4rem 0.8rem;
      font-size: 0.75rem;
    }
  }
  